import Page from 'core/components/Page';
import Text from 'core/components/Text';
import H1 from 'core/components/H1';
import H3 from 'core/components/H3';
import Link from 'core/components/Link';

import { PageIndent, Indent } from 'site/components/Wrappers';

import styles from './index.styl';

const title = 'Политика в отношении файлов cookie';

export default function CookiePolicy() {
  return (
    <Page title={title}>
      <PageIndent>
        <Text>
          <div className={styles.cookiesPolicy}>
            <Indent top={40} bottom={40}>
              <H1>{title}</H1>
            </Indent>
            <p>Сайт <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link> использует собственные технические и маркетинговые файлы cookie, а также файлы cookie партнеров (третьих лиц - поставщиков услуг) для того, чтобы предоставлять пользователям определенные возможности для просмотра и использования страниц сайта. Некоторые из них позволяют проверять качество работы и улучшать рабочие характеристики сайта, чтобы сделать его более удобным для пользователей, накапливать статистику посещений сайта и принимать меры по его улучшению, предоставлять рекламный контент пользователям с учетом их интересов. </p>
            <p>Большинство интернет-браузеров настроены принимать файлы cookie автоматически. При этом пользователь может самостоятельно изменить настройки своего браузера – отключить или ограничить использование cookie, получать уведомления об их использовании. Если используются различные устройства (планшет, смартфон, компьютер и т.д.), необходимо убедиться, что на каждом устройстве браузер настроен в соответствии с вашим решением по использованию файлов cookie. Для этого необходимо обратиться к инструкции браузера, чтобы выполнить необходимые настройки.</p>
            <p><strong>Внимание! Посещая сайт <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link>, и совершая акцепт настоящей политики путем нажатия кнопки &laquo;Принять&raquo; (или любой другой кнопки, позволяющей подтвердить факт принятия Пользователем настоящей Политики), Пользователь выражает свое согласие на обработку файлов cookie на условиях, изложенных в настоящей Политике. АО &laquo;МУМТ&raquo; информирует Пользователя, а Пользователь соглашается, с передачей полученных данных с помощью файлов cookie о Пользователе третьим лицам и получением Пользователем  маркетинговых материалов, размещаемых на сайте. АО &laquo;МУМТ&raquo; вправе использовать сторонние аналитические сервисы (например, сервис веб-аналитики &laquo;Яндекс Метрика&raquo;, Google analytics), а также осуществлять передачу (в случае необходимости трансграничную передачу) файлов cookie в адрес владельцев Аналитических сервисов, с которыми у АО &laquo;МУМТ&raquo; заключены соответствующие договоры и/или соглашения. </strong></p>
            <p><strong>Если вы не принимаете условия нашей Политики, вы можете изменить настройки браузера или прекратить просмотр страниц сайта. </strong></p>
            <p><strong>Однако блокирование всех cookie (включая важные) может закрыть доступ к нашему сайту или его отдельным функциям или разделам.</strong></p>
            <p>Как изменить настройки cookie, можно узнать, пройдя по указанным ниже ссылкам:</p>
            <ul>
              <li><Link to='https://support.google.com/chrome/answer/95647?hl=en'>Chrome</Link></li>
              <li><Link to='https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies'>Internet Explorer</Link></li>
              <li><Link to='http://help.opera.com/Windows/11.50/en/cookies.html'>Opera</Link></li>
              <li><Link to='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'>Firefox </Link></li>
              <li><Link to='https://support.apple.com/en-gb/HT201265'>Safari</Link></li>
            </ul>
            <p>Cookie — это небольшой файл, создаваемый сайтом и хранящийся локально в интернет-браузере или файловой системе вашего компьютера или мобильного устройства. </p>
            <p>Более подробную информацию об отдельных типах cookie, используемых нами в конкретных целях, можно найти ниже.</p>

            <p><strong>Сеансовые cookie</strong></p>

            <p>Сохраняются в браузере только на протяжении вашего сеанса в браузере, то есть до тех пор, пока вы не уйдете с сайта.</p>

            <p><strong>Постоянные cookie</strong></p>

            <p>Сохраняются в вашем браузере после завершения сеанса (если вы их не удалили).</p>

            <p><strong>Функциональные cookie</strong></p>

            <p>Позволяют веб-сайту запомнить все ваши варианты выбора, связанные с ним (например, изменение размера текста, страницы с пользовательскими настройками). Они также позволяют &quot;узнавать&quot; вас при каждом посещении сайта и персонализировать просмотр.</p>

            <p><strong>Cookie – сборщики информации</strong></p>
            <p>Получают и накапливают информацию о вашем пользовании веб-сайтом, в частности о том, какие веб-страницы вы посещали, о наличии сообщений об ошибках. Эти файлы не собирают персонально идентифицируемую информацию. Полученная информация накапливается таким образом, чтобы обеспечить ее анонимность. &laquo;Сборщики информации&raquo; используются для совершенствования работы веб-сайта.</p>
            <p><strong>Cookie, используемые в целях аналитики/оценки использования сайта/тестирования</strong></p>
            <p>Позволяют выявлять и подсчитывать количество посетителей и отслеживать их перемещение по нашему сайту. Благодаря этой информации мы оптимизируем работу нашего сайта, например, помогая пользователям быстрее находить нужную информацию, выявлять наиболее популярные разделы. Тестируя различные версии функций и параметров нашего сайта, мы делаем его удобнее для использования.</p>
            <p><strong>Чтобы узнать, как используют cookie наши партнеры, ознакомьтесь с их политикой конфиденциальности (по ссылкам ниже). Также мы приводим ссылки для отказа от их услуг (если вы считаете, что они вам не нужны).</strong></p>
            <p><strong>Мы используем сервисы Яндекс.Метрика, Google.Analytics, которые позволяют нам анализировать активность посетителей сайта и улучшать его работу. Эти сервисы получают данные на анонимной основе и не собирают сведения о личности посетителя сайта, не идентифицируют его как физическое лицо (не устанавливают личность). </strong></p>
            <p>Полученные сведения могут быть использованы нами, как владельцем сайта, наряду со сторонними организациями – нашими партнерами для улучшения работы сайта и его разделов. </p>

            <p><strong>Yandex</strong></p>
            <p>Эти технологии позволяют оценить эффективность рекламы, которую мы размещаем с помощью Yandex. <Link to='https://legal.yandex.com/privacy/'>Политика конфиденциальности Yandex</Link></p>

            <p><strong>Яндекс.Метрика</strong></p>
            <p>Условия использования сервиса API Яндекс.Метрика: <Link to='https://yandex.ru/legal/metrica_api/?lang=en'>https://yandex.ru/legal/metrica_api/?lang=en</Link></p>

            <p><strong>Google</strong></p>
            <p>Эти cookie позволяют оценить эффективность рекламы, которую мы размещаем с помощью Google. </p>
            <p><Link to='https://policies.google.com/technologies/ads?hl=en'>Как Google использует cookie в рекламе</Link></p>
            <p><Link to='https://policies.google.com/privacy?hl=en&gl=en'>Политика конфиденциальности Google</Link></p>

            <p><strong>Google Analytics</strong></p>
            <p>С его помощью мы можем провести оценку использования нашего сайта и услуг, а также получать статистические данные о посещении нашего сайта. </p>
            <p>Заблокировать аналитику Google можно по ссылке: <Link to='https://tools.google.com/dlpage/gaoptout?hl=en-GB'>https://tools.google.com/dlpage/gaoptout?hl=en-GB</Link></p>

            <H3>Отслеживание E-mail Рассылок</H3>

            <p>Некоторые электронные письма, которые мы Вам отправляем, могут содержать элементы, такие, как маячковый пиксель или ссылки отслеживания, которые позволяют нам определить, когда Вы открыли письмо, и выявить, по каким ссылкам из письма Вы переходили. Мы используем эту информацию, чтобы определить, какие элементы нашей электронной рассылки интересуют Вас больше всего.</p>

            <p>Вы можете убрать пиксель, удалив письмо. Если Вы не хотите скачивать пиксель на Ваш компьютер или другое устройство, Вы можете выбрать функцию получения рассылки в формате обычного текста, вместо HTML-формата. Также вы можете отписаться от нашей электронной рассылки, связавшись с нами по электронной почте <Link to='mailto:info@yourchoice.ru'>info@yourchoice.ru</Link>.</p>

            <H3>Изменения</H3>
            <p>Мы можем изменять нашу политику Cookie в любое время, уведомив Вас об изменениях в электронной рассылке или разместив их на нашем Сайте. Любые изменения вступают в силу в течение 7 дней с момента получения электронного письма или с момента появления обновленного документа на Сайте, в зависимости от того, какое событие произошло ранее. Если Вы продолжаете пользоваться сайтом после внесения изменений, это автоматически подтверждает Ваше согласие с новыми условиями.</p>

            <H3>Как с нами связаться</H3>

            <p>Электронная почта: <Link to='mailto:info@yourchoice.ru'>info@yourchoice.ru</Link></p>
          </div>
        </Text>
      </PageIndent>
    </Page>
  );
}
