import { useEffect, useState, useCallback, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import axios from 'core/libs/axios';
import RecaptchaWrapper from 'react-google-recaptcha';
import { withRouter } from 'core/libs/router';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Checkbox from 'core/components/Checkbox';
import Loader from 'core/components/Loader';
import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import AutoSuggest from 'site/components/AutoSuggest';
import Input from 'site/components/Input';
import Error from 'site/components/Error';
import Datepicker from 'site/components/Datepicker';
import { Indent } from 'site/components/Wrappers';
import { FieldName } from 'site/components/Texts';

import { RE_CAPTCHA_SITE_KEY, VERTICAL_INDENT } from 'site/constants';
import { checkisOzonSpec, checkIsPhoneSpec, sendGaEvent } from 'site/utils';

import styles from './index.styl';

const instanceForStaticFiles = axios.create({
  baseURL: '/spec',
  headers: { 'Content-Type': 'application/json' },
});

const SelectLoader = bindProps({ spinnerWidth: 18, spinnerHeight: 18 })(Loader);

function RegistrationForm({
  theme,
  handleInputChange,
  handleBlur,
  handleSubmit,
  errors,
  touched,
  values,
  formStates,
  location,
}) {
  const [marks, setMarks] = useState([]);
  const [cities, setCities] = useState([]);
  const recaptchaRef = useRef(null);
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);

  const { accountCreated, phoneVerified } = formStates || {};

  useEffect(() => {
    if (phoneVerified) {
      if (isOzonSpec) {
        sendGaEvent('OZON_form_1', 'phone_number_confirmed_ozon_form_1');
      }
      if (isPhoneSpec) {
        sendGaEvent('OZON_form_2', 'phone_number_confirmed_ozon_form_2');
      }
    }
  }, [isOzonSpec, isPhoneSpec, phoneVerified]);

  useEffect(() => {
    if (marks.length > 0) return;

    instanceForStaticFiles.get('/marks.json')
      .then(({ data }) => setMarks(prevState => ([...prevState, ...data])))
      .catch(error => console.error(error));
  }, [marks]);

  useEffect(() => {
    if (cities.length > 0) return;

    instanceForStaticFiles.get('/cities.json')
      .then(({ data }) => setCities(prevState => ([...prevState, ...data])))
      .catch(error => console.error(error));
  }, [cities]);

  const onSubmit = useCallback(event => {
    const token = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    handleSubmit(event, { token, cities, marks });
  }, [handleSubmit, cities, marks]);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.rules}
          font 12px/15px ${theme.fonts.display}
          color ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='text'
          label='Имя'
          name='first_name'
          onChange={handleInputChange}
          onBlur={event => {
            if (isOzonSpec) {
              sendGaEvent('OZON_form_1', 'name_ozon_form_1');
            }
            if (isPhoneSpec) {
              sendGaEvent('OZON_form_2', 'name_ozon_form_2');
            }
            handleBlur(event);
          }}
          value={values.first_name}
          error={touched.first_name && errors.first_name ? errors.first_name : ''}
          disabled={accountCreated}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='email'
          label='E-mail'
          name='email'
          onChange={handleInputChange}
          onBlur={event => {
            if (isOzonSpec) {
              sendGaEvent('OZON_form_1', 'email_ozon_form_1');
            }
            if (isPhoneSpec) {
              sendGaEvent('OZON_form_2', 'email_ozon_form_2');
            }
            handleBlur(event);
          }}
          value={values.email}
          error={touched.email && errors.email ? errors.email : ''}
          {...isOzonSpec && { hint: 'На этот E-mail придёт письмо с сертификатом Ozon' }}
          {...isPhoneSpec && { hint: 'На этот E-Mail придёт письмо с кодом на пополнение баланса телефона' }}
          disabled={accountCreated}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Datepicker
          value={values.birthdate}
          onChange={handleInputChange}
          onBlur={event => {
            if (isOzonSpec) {
              sendGaEvent('OZON_form_1', 'birthday_ozon_form_1');
            }
            if (isPhoneSpec) {
              sendGaEvent('OZON_form_2', 'birthday_ozon_form_2');
            }
            handleBlur(event);
          }}
          label='Дата рождения'
          name='birthdate'
          error={touched.birthdate && errors.birthdate ? errors.birthdate : ''}
          className={styles.date}
          disabled={accountCreated}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <AutoSuggest
          label='Город'
          placeholder='-- Укажи город --'
          name='city'
          onChange={handleInputChange}
          onBlur={event => {
            if (isOzonSpec) {
              sendGaEvent('OZON_form_1', 'city_ozon_form_1');
            }
            if (isPhoneSpec) {
              sendGaEvent('OZON_form_2', 'city_ozon_form_2');
            }
            handleBlur(event, { cities });
          }}
          value={values.city}
          options={cities}
          error={touched.city && errors.city ? errors.city : ''}
          disabled={accountCreated}
          {...(cities.length === 0) && {
            icon: SelectLoader,
            disabled: true,
          }}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <AutoSuggest
          label='Бренд'
          placeholder='-- Укажи бренд --'
          name='brand_name'
          onChange={handleInputChange}
          onBlur={event => {
            if (isOzonSpec) {
              sendGaEvent('OZON_form_1', 'brand_ozon_form_1');
            }
            if (isPhoneSpec) {
              sendGaEvent('OZON_form_2', 'brand_ozon_form_2');
            }
            handleBlur(event, { marks });
          }}
          value={values.brand_name}
          options={marks}
          hint='Начни вводить название бренда. Например: Вейп, Glo, Kent и т.п.'
          error={touched.brand_name && errors.brand_name ? errors.brand_name : ''}
          disabled={accountCreated}
          {...(cities.length === 0) && {
            icon: SelectLoader,
            disabled: true,
          }}
        />
      </div>
      <div className={cx(styles.row, styles.phoneGroup)}>
        <InputMask
          mask='+7 (999) 999-99-99'
          onChange={handleInputChange}
          onBlur={event => {
            if (isOzonSpec) {
              sendGaEvent('OZON_form_1', 'phone_number_input_ozon_form_1');
            }
            if (isPhoneSpec) {
              sendGaEvent('OZON_form_2', 'phone_number_input_ozon_form_2');
            }
            handleBlur(event);
          }}
          value={values.phone}
          disabled={accountCreated}
        >
          <Input
            type='tel'
            label='Телефон'
            name='phone'
            className={styles.phone}
            {...phoneVerified ? {
              success: 'Телефон подтвержден',
            } : {
              hint: 'На этот телефон придёт код подтверждения',
              error: touched.phone && errors.phone ? errors.phone : '',
            }}
          />
        </InputMask>
      </div>
      <div className={styles.row}>
        <Checkbox
          defaultChecked={values.terms_and_conditions}
          name='terms_and_conditions'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.terms_and_conditions}
          disabled={accountCreated}
        >
          <FieldName className={scope.wrapClassNames(styles.rules)}>Подтверждаю&nbsp;
            <Link
              type='secondary'
              target='_blank'
              to='/agreement_reg_spec'
            >
              согласие на обработку персональных данных
            </Link>&nbsp;и получение информационных рассылок
            <Indent top={10} />
            <Link
              type='secondary'
              target='_blank'
              {...isOzonSpec && { to: '/spec/pravila_ozon.pdf' }}
              {...isPhoneSpec && { to: '/spec/pravila_phone.pdf' }}
            >
              Правила участия
            </Link>
          </FieldName>
        </Checkbox>
        <Error error={errors.terms_and_conditions} />
      </div>

      {(!accountCreated && !phoneVerified) && (
        <>
          <Indent top={VERTICAL_INDENT}>
            <RecaptchaWrapper
              size='normal'
              sitekey={RE_CAPTCHA_SITE_KEY}
              ref={recaptchaRef}
            />
          </Indent>
          <Indent top={VERTICAL_INDENT}>
            <div className={cx(styles.field, styles.footer)}>
              <Button typeAttribute='submit' disabled={Object.keys(errors).length > 0}>
                Отправить код на телефон
              </Button>
            </div>
          </Indent>
        </>
      )}
      <scope.styles />
    </form>
  );
}

RegistrationForm.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
  serverErrorStatus: PropTypes.number,
  theme: PropTypes.object,
  formStates: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(withTheme(RegistrationForm));
