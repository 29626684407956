import bindProps from 'core/components/bindProps';

import Ad from '.';

export const Billboard = bindProps({
  name: 'Billboard',
  height: 250,
  ownerId: 264483,
  params: {
    p1: 'cpatz',
    p2: 'hfct',
  },
})(Ad);

export const StickyLine = bindProps({
  name: 'Shtorka',
  ownerId: 264483,
  params: {
    p1: 'cpavd',
    p2: 'hfcu',
  },
})(Ad);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  ownerId: 264483,
  params: {
    p1: 'cpcuu',
    p2: 'hfhg',
  },
})(Ad);

export const Ad280x430 = bindProps({
  name: 'Ad280x430',
  ownerId: 264483,
  params: {
    p1: 'cpcut',
    p2: 'hfhh',
  },
})(Ad);

export const Ad220x338 = bindProps({
  name: 'Ad220x338',
  ownerId: 264483,
  params: {
    p1: 'cpcus',
    p2: 'hfhi',
  },
})(Ad);

export const Slider = bindProps({
  name: 'Slider',
  ownerId: 264483,
  params: {
    p1: 'cqwlw',
    p2: 'hinx',
  },
})(Ad);

export const Screenglide = bindProps({
  name: 'Screenglide',
  ownerId: 264483,
  height: 250,
  params: {
    p1: 'cqwlx',
    p2: 'hioc',
  },
})(Ad);

export const Interscroller = bindProps({
  name: 'Interscroller',
  ownerId: 264483,
  params: {
    p1: 'cqwlv',
    p2: 'hiny',
  },
})(Ad);

export const BillboardSpec = bindProps({
  name: 'BillboardSpec',
  ownerId: 264483,
  params: {
    p1: 'csbti',
    p2: 'hfct',
  },
})(Ad);

export const Background = bindProps({
  name: 'Background',
  ownerId: 264483,
  params: {
    p1: 'crowu',
    p2: 'hind',
  },
})(Ad);

export const Footer = bindProps({
  name: 'Footer',
  ownerId: 264483,
  params: {
    p1: 'csady',
    p2: 'hllm',
  },
})(Ad);
