import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import Text from 'core/components/Text';
import H1 from 'core/components/H1';
import H3 from 'core/components/H3';
import Link from 'core/components/Link';

import withTheme from 'core/components/theme';

import { PageIndent, Indent } from 'site/components/Wrappers';

import styles from './index.styl';

const title = 'Условия использования сайта';

function Rules({ theme }) {
  return (
    <Page title={title}>
      <style jsx>{`
        .${styles.rules}
          ol
            li
              &:before
                font ${theme.texts.title3.font}

              ol
              ul
                li:before
                  font ${theme.texts.body.font}
      `}</style>
      <PageIndent>
        <Text>
          <div className={styles.rules}>
            <Indent top={40} bottom={40}>
              <H1>{title}</H1>
            </Indent>
            <ol>
              <li>
                <H3>Общие положения</H3>
                <p>Данный веб-сайт (далее по тексту – «Сайт») принадлежит Акционерному обществу «Международные услуги по маркетингу табака», входящему в «группу компаний ITMS» (далее по тексту — «Компания»). Просим Вас внимательно ознакомиться с настоящими Условиями пользования Сайтом (далее по тексту – «Соглашение»). Использование настоящего Сайта представляет собой соглашение, предусматривающее настоящие условия. Соглашение вступает в силу с момента выражения Вами (далее по тексту - «Пользователь») согласия с его условиями. При этом регистрация Пользователя на Сайте означает полное и безоговорочное принятие им условий настоящего Соглашения. Если вы не согласны соблюдать условия настоящего Соглашения, вам не следует заходить на данный веб-сайт и знакомиться с представленной на нём информацией.</p>
                <ol>
                  <li>Настоящее Соглашение является открытым и общедоступным документом.</li>
                  <li>Неотъемлемой частью настоящего Соглашения являются также все специальные документы, регулирующие порядок предоставление отдельных сервисов Сайта и/или проведения на нем активностей и размещенные в соответствующих разделах Сайта.</li>
                  <li>Настоящее Соглашение может быть в любое время изменено и/или дополнено Компанией в одностороннем порядке без какого-либо специального уведомления путем размещения обновленного текста на Сайте.</li>
                  <li>Компания рекомендует Пользователям регулярно проверять условия настоящего Соглашения на предмет их изменения и/или дополнения. Продолжение использования Сайта Пользователем после внесения изменений и/или дополнений в настоящее соглашение означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</li>
                  <li>Настоящее Соглашение является бессрочным и может быть прекращено в любое время по инициативе Компании или Пользователя.</li>
                  <li>Данный Сайт предназначен исключительно для граждан России старше 18 лет Лицам, не достигшим совершеннолетия, пользование Сайтом запрещено. Регистрируясь на сайте, пользователь подтверждает, что не является гражданином стран – членов Европейского союза.</li>
                  <li>Информация на Сайте предоставляется Пользователям бесплатно, без каких-либо поручительств или гарантий, за исключением специально оговоренных случаев.</li>
                  <li>Ни один из материалов, размещенных на Сайте не должен рассматриваться Пользователем, как преуменьшения риска для здоровья, связанного с курением.</li>
                  <li>Если Вы полагаете, что какой-нибудь материал на Сайте является несоответствующим или оскорбительным, равно как какое-либо из условий Соглашения является неприемлемым для Вас, пожалуйста, просим Вам прекратить использование настоящего Сайта, а также уведомить Компанию для удаления Вашей учетной записи на Сайте и всей информации, указанной Вами в личном кабинете.</li>
                  <li>Все вопросы и предложения, касающиеся данного Сайта, просим Вас высылать, используя информацию, размещенную в разделе «Обратная связь».</li>
                  <li>Компания также сохраняет за собой право в любое время изменять оформление Сайта, его содержание, любой или весь контент, список сервисов, изменять или дополнять используемые скрипты, программное обеспечение и другие объекты, используемые или хранящиеся на Сайте, любые серверные приложения, ограничить доступ к Сайту или к отдельным его разделам в любое время с предварительным уведомлением или без такового.</li>
                  <li>Информация, содержащаяся на Сайте может содержать неточности и опечатки. Компания не несет обязательств регулярно обновлять информацию на Сайте.</li>
                  <li>За исключением случаев, специально оговоренных на данном веб-сайте, Компания не несет ответственности за содержание / использование информации на Сайте, а также содержание / использование сообщений, отправленных либо полученных Пользователем.</li>
                  <li>Компания сохраняет за собой право в любое время и без предупреждения расширять, видоизменять, приостанавливать работу или полностью закрывать весь Сайт или любую его часть, а также ограничивать либо запрещать доступ к нему.</li>
                  <li>Компания не гарантирует беспрерывную и безошибочную работу Сайта, периодическую коррекцию его дефектов и его совместимость с Вашим компьютером, аппаратным обеспечением и программным обеспечением.</li>
                  <li>Настоящее Соглашение регулируются и толкуются в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации.</li>
                  <li>Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений.</li>
                </ol>
              </li>
              <li>
                <H3>Ссылки на сайты третьих лиц</H3>
                <ol>
                  <li>Данный Сайт может содержать ссылки на сайты, принадлежащие или используемые другими лицами. Такие ссылки предоставляются исключительно для Вашего удобства. Компания не контролирует и не несет ответственности за их использование, содержание, положения о конфиденциальности либо безопасность таких сайтов, включая, в том числе, любые мнения или утверждения, выраженные на сайтах третьих лиц или в их Контенте.</li>
                  <li>Без ограничения вышесказанного, Компания особо оговаривает отсутствие ответственности, если такие сайты:</li>
                  <p>
                    <ul>
                      <li>нарушают права интеллектуальной собственности третьих лиц;</li>
                      <li>неточны, неполны и вводят в заблуждение;</li>
                      <li>непригодны в коммерческих или иных конкретных целях;</li>
                      <li>не обеспечивают должной безопасности;</li>
                      <li>содержат вирусы и иные элементы деструктивного характера;</li>
                      <li>носят пасквильный или клеветнический характер.</li>
                    </ul>
                  </p>
                  <li>В случае установки Пользователем ссылки на вышеуказанные сайты или Сайт, Пользователь делает это на собственный риск.</li>
                  <li>Ссылка на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная на Сайте, не является одобрением или рекомендацией данных продуктов (услуг) со стороны Компании.</li>
                </ol>
              </li>
              <li>
                <H3>Исключительные права</H3>
                <ol>
                  <li>Все объекты, размещенные на Сайте, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их подборки (далее по тексту — «Контент»), являются объектами исключительных прав Компании, Пользователей Сайта и других правообладателей, все права на эти объекты защищены.</li>
                  <li>Кроме случаев, установленных настоящим Соглашением, а также действующим законодательством Российской Федерации, никакой Контент не может быть скопирован (воспроизведен), переработан, распространен, отображен, опубликован, скачан, передан, продан или иным способом использован целиком или по частям без предварительного разрешения правообладателя, кроме случаев, когда правообладатель явным образом выразил свое согласие на свободное использование Контента любым лицом.</li>
                  <li>Настоящим Соглашением Пользователю не предоставляются какие-либо исключительные права на интеллектуальную собственность, размещенную на Сайте.</li>
                  <li>
                    <p>Пользователь, размещая на Сайте принадлежащий ему на законных основаниях Контент, передает Компании на весь срок охраны авторского права, предусмотренный законодательством РФ, неисключительное право на его постоянное и безвозмездное использование, включая право переуступки, путем просмотра, воспроизведения (в том числе копирования), перевода, доведения до всеобщего сведения, переработки (в том числе на распечатку копий), изменения, адаптации, публичного исполнения, публикации, создания производной продукции, распространения, исполнения, по всему миру и/или включение его в другие произведения, распространяющиеся как уже известным, так и любым пока не изобретенным способом, без ограничения срока действия этих прав и любых других прав, которые могут быть связаны с присланным или размещенным Пользователем материалом, с соблюдением условий конфиденциальности, перечисленных в настоящем Соглашении.</p>
                    <p>При этом, Компания вправе также использовать Контент Пользователя вышеуказанным способом для целей Сайта или в связи с ними, в том числе для его популяризации. Для указанных целей Компания также может изготавливать производные произведения или вставлять Контент Пользователя в качестве составных частей в соответствующие сборники, совершать иные действия, служащие достижению указанных целей.</p>
                    <p>Пользователь подтверждает и соглашается с тем, что ему не будет выплачено вознаграждение или предоставлена компенсация в ином виде за использование Контента, а также с тем, что Компания не обязано указывать Пользователя в качестве автора Контента.</p>
                  </li>
                  <li>Если Пользователь удаляет свой Контент с Сайта, права, упомянутые в п. 3.4. Соглашения будут автоматически отозваны, однако Компания вправе сохранять архивные копии пользовательского Контента в течение неопределенного срока.</li>
                  <li>Компания не производит возврат Пользователю любых материалов, размещенных Пользователем на Сайте.</li>
                  <li>Пользователь не вправе загружать или иным образом доводить до всеобщего сведения (публиковать на Сайте) Контент других сайтов, баз данных и прочие результаты интеллектуальной деятельности при отсутствии явным образом выраженного согласия правообладателя на такие действия.</li>
                  <li>Любое использование Сайта или Контента, кроме разрешенного в настоящем Согласии или в случае явно выраженного согласия правообладателя на такое использование, без предварительного письменного разрешения правообладателя, категорически запрещено.</li>
                  <li>Если иное явным образом не установлено в настоящем Соглашении, ничто в нем не может быть рассмотрено как передача исключительных прав на Контент.</li>
                  <li>Никакие положения настоящего Соглашения не предоставляют Пользователю право на использование фирменного наименования, товарных знаков, доменных имен и иных отличительных знаков Компании.</li>
                  <li>Пользователь несет личную ответственность за любой Контент или иную информацию, которые он загружает или иным образом доводит используем и до всеобщего сведения (публикует) на Сайте или с его помощью. Пользователь не имеет права загружать, передавать или публиковать Контент на Сайте, если он не обладает соответствующими правами на совершение таких действий, приобретенными или переданными ему в соответствии с законодательством Российской Федерации.</li>
                  <li>Компания может, но не обязана, просматривать Сайт на наличие запрещенного Контента и может удалять или перемещать (без предупреждения) любой Контент или пользователей по своему личному усмотрению, по любой причине или без причины, включая без всяких ограничений перемещение или удаление Контента, который, по субъективному мнению ответственных лиц Компании, нарушает настоящее Соглашение, законодательство Российской Федерации и/или может нарушать права, причинить вред или угрожать безопасности других Пользователей или третьих лиц.</li>
                  <li>
                    <p>Размещая на Сайте Контент, Пользователь подтверждает, что:</p>
                    <ul>
                      <li>такие материалы являются собственным произведением Пользователя и\или что Пользователь обладает правом предоставлять его Компании для всех вышеперечисленных целей; а также;</li>
                      <li>такие материалы не содержат клевету и/или какую-либо информацию, оскорбляющую честь и достоинство личности; а также;</li>
                      <li>размещение таких материалов, равно как и сами материалы, не нарушает никаких законов.</li>
                      <li>Пользователь обязуется возместить Компании любые судебные издержки, ущерб и другие затраты, которые она может понести в результате нарушения Пользователем указанных в настоящем Соглашении условий.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <H3>Порядок предоставление доступа</H3>
                <ol>
                  <li>Для получения доступа к пользованию Сайтом Пользователь должен достичь совершеннолетия (18 лет).</li>
                  <li>Доступ предоставляется бесплатно и только для совершеннолетних лиц.</li>
                  <li>При регистрации на Сайте Пользователь обязан предоставить Компании необходимую достоверную и актуальную информацию для формирования персональной страницы Пользователя (Личный кабинет), включая уникальные для каждого Пользователя телефон и пароль доступа к Сайту, а также фамилию, имя, адрес электронной почты и информацию о возрасте Пользователя. </li>
                  <li>Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие законодательству Российской Федерации предоставленной при регистрации информации. </li>
                  <li>После подтверждения совершеннолетия Пользователем Компания предоставляет ему доступ на Сайт.</li>
                  <li>Один пользователь вправе иметь несколько учетных записей на Сайте (Профилей).</li>
                  <li>Выбранные Пользователем логин и пароль являются необходимой и достаточной информацией для доступа Пользователя на Сайт. Пользователь не имеет права передавать свои логин и пароль третьим лицам, несет полную ответственность за их сохранность, самостоятельно выбирая способ их хранения. Пользователь на используемом им аппаратно-программном обеспечении может разрешить хранение логина и пароля (с использованием файлов cookies) для последующей автоматической авторизации на Сайте.</li>
                  <li>Если Пользователем не доказано обратное, любые действия, совершенные с использованием его логина и пароля, считаются совершенными соответствующим Пользователем. В случае несанкционированного доступа к логину и паролю и/или персональной странице Пользователя, или распространения логина и пароля Пользователь обязан незамедлительно сообщить об этом Компании любым доступным способом.</li>
                  <li>Не допускается продажа или передача иным образом Пользователем другому лицу или организации данных, необходимых для получения доступа на Сайт.</li>
                  <li>Доступ к Сайту может быть заблокирован и/или прекращен в результате нарушения Пользователем настоящего Соглашения, изменения законодательства или условий настоящего Соглашения, либо по инициативе самого Пользователя.</li>
                  <li>
                    <p>В случае прекращения доступа к Сайту:</p>
                    <ul>
                      <li>логин и пароль деактивируются.</li>
                      <li>весь Контент, загруженный Пользователем ранее, может быть удален Компанией. При этом Компания вправе продолжить его использования для архивации и в справочных целях.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <H3>Права и обязанности пользователя</H3>
                <ol>
                  <li>Пользователь не имеет право копировать, воспроизводить, переиздавать, загружать, публиковать, транслировать, передавать, предоставлять в общественное пользование или другим образом использовать содержание Сайта, за исключением использования в личных, некоммерческих целях.</li>
                  <li>Пользователь соглашается не адаптировать, не изменять и не создавать производных работ ни из каких элементов содержания Сайта, за исключением личных, некоммерческих целей. Любое другое использование содержания Сайта возможно лишь с предварительного письменного согласия Компании.</li>
                  <li>Пользователь обязан использовать Сайт исключительно в законных целях и таким образом, чтобы не нарушать права других пользователей, не ограничивать и не препятствовать доступу других людей к Сайту и пользованию им.</li>
                  <li>
                    <p>Пользователь осознает и соглашается с тем, что в связи с его использованием Сайта он должен:</p>
                    <ul>
                      <li>самостоятельно и за свой счет оплачивать доступ в сеть Интернет,</li>
                      <li>иметь оборудование, необходимое ему, чтобы получить доступ в Интернет, включая компьютер, программное обеспечение, роутер, модем или иное средство подключения к Интернету.</li>
                    </ul>
                  </li>
                  <li>
                    <p>Пользователь обязуется не использовать Сайт для загрузки, посылки, передачи или любого другого способа размещения материалов, которые:</p>
                    <ul>
                      <li>защищены авторским правом, или правом на интеллектуальную собственность, если у вас нет разрешения на это от нас или от владельца авторского права;</li>
                      <li>являются незаконными, вредоносными, угрожающими, клеветническими, оскорбляют нравственность, пропагандируют ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержат оскорбления в адрес конкретных лиц или организаций;</li>
                      <li>ограничивают или запрещают любому другому пользователю использование и наслаждение Сайтом,</li>
                      <li>поощряют незаконное поведение, или (e) содержат вирус или другой вредный компонент, рекламу любого вида, или ложные или вводящие в заблуждение факты.</li>
                    </ul>
                  </li>
                  <li>Пользователь при посещении Сайта и получении доступа (регистрации) гарантирует, что он не: (a) выдает себя за другого человека или представителя организации и/или сообщества без достаточных на то прав; (b) загружает, отправляет, публикует, передает, воспроизводит, или по-другому использует любую информацию или материал, полученный через Сайт, в коммерческих целях (кроме случаев, когда это явно разрешено поставщиком такой информации или другого материала); (c) участвует в рассылке спама; или (d) пытается получить несанкционированный доступ к другим компьютерным системам через Сайт.</li>
                  <li>Если это явно не разрешено, то Пользователь не имеете права загружать, публиковать, воспроизводить, передавать содержание Сайта.</li>
                  <li>Пользователь обязан предоставлять достоверные, полные и актуальные данные, следить за их актуализацией; информировать Компанию о несанкционированном доступе к персональной странице и/или о несанкционированном доступе и/или использовании пароля и логина Пользователя.</li>
                  <li>
                    <p>Пользователю обязуется не совершать следующих действий:</p>
                    <ul>
                      <li>регистрироваться в качестве Пользователя от имени или вместо другого лица («фальшивый аккаунт») или регистрировать группу (объединение) лиц или юридическое лицо в качестве Пользователя;</li>
                      <li>вводить в заблуждение относительно своей личности, используя логин и пароль другого зарегистрированного пользователя;</li>
                      <li>искажать сведения о себе, включая, но не ограничиваясь сведениями о своем возрасте;</li>
                      <li>незаконно загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать интеллектуальную собственность пользователей и третьих лиц;</li>
                      <li>использовать программное обеспечение и осуществлять действия, направленные на нарушение нормального функционирования Сайта и его сервисов;</li>
                      <li>загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать вирусы, трояны и другие вредоносные программы;</li>
                      <li>использовать автоматизированные скрипты (программы) для сбора информации на Сайте и (или) взаимодействия с Сайтом и его сервисами;</li>
                      <li>любым способом, в том числе, но не ограничиваясь, путем обмана, злоупотребления доверием, взлома, пытаться получить доступ к логину и паролю другого пользователя;</li>
                      <li>осуществлять незаконные сбор и обработку персональных данных других лиц;</li>
                      <li>размещать любую другую информацию, которая, по мнению Компании, является нежелательной, не соответствует целям создания Сайта, ущемляет интересы Пользователей или по другим причинам является нежелательной для размещения на Сайте.</li>
                    </ul>
                  </li>
                  <li>В случае несогласия Пользователя с настоящим Соглашением или его обновлениями, Пользователь обязан отказаться от использования Сайта, проинформировав об этом Компанию любым возможным способом.</li>
                  <li>
                    <p>Пользователь вправе цитировать текстовые материалы (литературных произведений, статей), опубликованных на Сайте, с обязательным соблюдением следующих условий:</p>
                    <ul>
                      <li>объем цитирования не может превышать 10% от общего объема текста;</li>
                      <li>
                        <p>при цитировании обязательно указание имени автора текста, наименования Сайта и гиперссылки на Сайт.</p>
                        <p>Любое иное использование статей, фотографий, иллюстраций и всех других материалов Сайта без письменного разрешения Компании, в том числе копирование (включая запись на носители информации), воспроизведение (включая воспроизведение на узлах сети Интернет для любых целей, включая обзоры), переработка, распространение, передача в эфир, сообщение по кабелю для всеобщего сведения, доведение до всеобщего сведения через сеть Интернет, любым иным способом, запрещено и влечет ответственность, предусмотренную законодательством РФ о защите интеллектуальной собственности.</p>
                      </li>
                    </ul>
                  </li>
                  <li>Выполнять иные обязанности, которые изложены в соответствующих разделах Сайта.</li>
                  <li>
                    <p>При предоставлении фотографий для размещения на сайте <Link to='https://yourchoice.ru'>https://www.yourchoice.ru</Link>, запрещается высылать контент, который содержит:</p>
                    <ul>
                      <li>изображения табачной продукции (сигареты, сигары и т.п.), табачных изделий и/или аксессуаров, изображений потребительской тары (упаковок) такой продукции, электронных сигарет и их упаковок, а также товарные знаки (логотипы) табачной продукции, электронных сигарет, зажигалок и иных табачных аксессуаров;</li>
                      <li>изображения детей и/или беременных женщин;</li>
                      <li>демонстрацию процесса курения, табачного дыма или процесса использования электронных сигарет;</li>
                      <li>демонстрацию полностью или частично обнаженного тела;</li>
                      <li>информацию, нарушающую и ущемляющую права и свободы людей, касающиеся национальной, расовой, этнической принадлежности, вероисповедания или по какому-либо иному признаку, материалы, пропагандирующие ненависть или дискриминацию людей по какому-либо признаку, способствующие разжиганию межнациональной или межрасовой ненависти; материалы порнографического характера, сцены насилия, материалы, содержащие какие-либо другие элементы, оскорбляющие нравственные и моральные ценности.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <H3>Персональные данные и иная конфиденциальная информация</H3>
                <ol>
                  <li>Компания считает приоритетом обеспечения безопасности персональных данных и тайны частной жизни Пользователей.</li>
                  <li>Обработка персональных данных Пользователя осуществляется в соответствии с законодательством Российской Федерации и Политикой в отношении обработки персональных данных и сведения о реализуемых требованиях к защите персональных данных.</li>
                  <li>Переданные Пользователем персональные данные могут обрабатываться Компанией (сбор, запись, систематизация, накопление, хранение, уточнение, изменение, извлечение, использование, блокирование, удаление, уничтожение, а также иные способы обработки, включая, но не ограничиваясь передачей данных третьим лицам, с которыми у Компании заключены <Link to='https://www.itms.ru/upload/pdn_partners_list_mumt.docx'>договоры</Link>, в том числе с правом осуществлять трансграничную передачу данных на территорию стран, обеспечивающих адекватную защиту персональных данных, как с применением средств автоматизации, так и без использования таких средств, исключительно для осуществления деятельности Компании, для формирования информационной базы данных о потребителях табачной продукции «Бритиш Американ Тобакко», для предоставления информации о вышеуказанной табачной продукции, для проведения опросов и социальных исследований, а также для направления иной информации, связанной с деятельностью Компании и проводимыми мероприятиями.</li>
                  <li>«Профиль» Пользователя, размещенный в разделе «Личный кабинет», не может быть доступен для просмотра другим пользователям настоящего сайта.</li>
                  <li>Компания осуществляет обработку полученных персональных данных в течение срока, указанного в согласии, до отзыва пользователем согласия на обработку персональных данных путем направления его по электронной почте. В отзыве согласия должны быть указаны идентифицирующие Пользователя сведения, содержащиеся в его профиле: фамилия, имя отчество, адрес электронной почты. Компания вправе также в одностороннем порядке прекратить оказание информационных услуг на Сайте, уведомив об этом путем размещения соответствующий информации не менее, чем за 30 дней до такого прекращения, после чего персональные данные Пользователей будут уничтожены предусмотренным законодательством порядком.</li>
                  <li>Компания обрабатывает полученные персональные данные Пользователей, обеспечивая их конфиденциальность и безопасность. Принципы и условия обработки персональных данных изложены в Политике в отношении обработки персональных данных и сведениях о реализуемых требованиях к защите персональных данных.</li>
                  <li>Компания не продает и не раскрывает полученные персональные данные Пользователей, каким-либо третьим лицам для их собственных маркетинговых целей.</li>
                  <li>Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по истечении 2 (двух) дней с момента размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта. Переходя по ссылке <Link to='https://yourchoice.ru/'>https://www.yourchoice.ru/</Link>, Пользователь подтверждает, что принимает условия настоящего Соглашения, а также Политики в отношении обработки персональных данных и сведениях о реализуемых требованиях к защите персональных данных, являющейся неотъемлемой частью настоящего Соглашения и размещенной на странице по адресу <Link to='https://yourchoice.ru/policy#terminy-i-opredeleniya'>https://www.yourchoice.ru/terminy-i-opredeleniya</Link></li>
                </ol>
              </li>
              <li>
                <H3>Ответственность</H3>
                <ol>
                  <li>Нарушение настоящего Соглашения и действующего законодательства Российской Федерации может повлечь за собой гражданско-правовую, административную и уголовную ответственность.</li>
                  <li>Компания не гарантирует, что содержание и работа Сайта будут непрерывными и безошибочными, что дефекты будут исправляться, а также что Сайт или сервер, обеспечивающий доступ к этому сайту, не содержат вирусов и программных ошибок.</li>
                  <li>Пользователи несут ответственность за собственные действия в связи с созданием и размещением информации на Сайте в соответствии с действующим законодательством Российской Федерации.</li>
                  <li>Компания не несет ответственности за действия или бездействие любых лиц в отношении использования Сайта.</li>
                  <li>В информационной системе Сайта и его программном обеспечении отсутствуют технические решения, осуществляющие автоматические цензуру и контроль действий и информационных отношений Пользователей по использованию Сайта.</li>
                  <li>Компания не несет ответственности за нарушение Пользователем настоящего Соглашения и оставляет за собой право по своему собственному усмотрению, а также при получении информации от других пользователей либо третьих лиц о нарушении Пользователем настоящего Соглашения, изменять (модерировать) или удалять любую публикуемую Пользователем информацию, приостанавливать, ограничивать или прекращать доступ Пользователя ко всем или к любому из разделов или сервисов Сайта в любое время по любой причине или без объяснения причин, с предварительным уведомлением или без такового, не отвечая за любой вред, который может быть причинен Пользователю таким действием.</li>
                  <li>Компания вправе удалить персональные данные Пользователя и(или) приостановить, ограничить или прекратить его доступ к любому из сервисов Сайта, если Компания обнаружит, что по ее мнению, Пользователь представляет угрозу для Сайта и(или) остальных пользователей. В этом случае, Компания не несет ответственности за осуществленное в соответствии с настоящим Соглашением временное блокирование или удаление информации, или удаление персональных данных (прекращение регистрации) Пользователя.</li>
                  <li>Компания не несет ответственности за временные сбои и перерывы в работе Сайта и вызванные ими потерю информации.</li>
                  <li>Компания не несет ответственности за любой ущерб компьютеру Пользователя или иного лица, мобильным устройствам, любому другому оборудованию или программному обеспечению, вызванный или связанный со скачиванием материалов с Сайта или по ссылкам, размещенным на Сайте.</li>
                  <li>Пользователь обязуется возместить Компании ущерб в связи или в результате нарушения настоящего Соглашения, равно как и при возникновении технических сбоев в работе Сайта или систем передачи данных между Сайтом и Пользователем или иными лицами, возникших по вине самого Пользователя или любых иных лиц, осуществляющих доступ на Сайт с использованием данных, предоставленных Пользователю.</li>
                </ol>
              </li>
            </ol>
          </div>
        </Text>
      </PageIndent>
    </Page>
  );
}

Rules.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Rules);
