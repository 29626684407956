import PropTypes from 'prop-types';
import { useContext } from 'react';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import { topicQuery } from 'core/queries/topic';

import { denormalizeData } from 'core/utils/api';
import { withBreakpoint } from 'core/components/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import Topic from 'core/components/Topic';
import PageLoader from 'core/components/Loader/PageLoader';

import { VOID_QUERY } from 'core/site/constants';

import { PageIndent } from 'site/components/Wrappers';
import RegWall from 'site/components/RegWall';
import BatIdContext from 'site/components/BatId';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';

const relationships = resolveRelationships(...[
  ['content', 'tags'],
  {},
  { content: {}, tags: [] },
]);

function topicRenderer(topic, enhancedTopic, batId, list3Topics, additionalTopics) {
  const {
    attributes: {
      is_premium: isPremium,
    },
  } = topic;

  const lockForUnauthorized = isPremium && !batId.isAuthorized;

  return (
    <>
      <TopicHeader content={topic} />
      <TopicContent
        content={topic}
        hideContent={lockForUnauthorized}
        list3Topics={list3Topics}
      />
      {lockForUnauthorized ? <RegWall /> : <TopicFooter content={topic} additionalTopics={additionalTopics} />}
    </>
  );
}


function TopicPage(props) {
  const { history, isMobile } = props;

  const batId = useContext(BatIdContext);

  const { data: content, isTopicLoading } = useRequest(topicQuery(props));

  const {
    tags,
  } = relationships(content);

  // list3Topics
  const {
    data: list3Topics,
    isLoading: isList3TopicsLoading,
  } = useRequest(
    topicsQuery({
      list: 'main_middle_slider3',
      sort: 'list',
      include: 'image,rubric',
      history,
    }, {
      select: denormalizeData,
    }),
  );

  const requestTags = tags?.map(t => t.attributes.slug).join(',');
  const hasRequestTags = !!requestTags?.length;

  const queryParams = hasRequestTags ? {
    tag: requestTags,
    limit: isMobile ? 4 : 12,
    excluded_ids: content?.id,
    include: 'image,rubric,authors',
    history,
  } : null;

  // additionalTopics
  const {
    data: additionalTopics,
    isLoading: isAdditionalTopicsLoading,
  } = useRequest(
    hasRequestTags
      ? topicsQuery(queryParams, { select: denormalizeData })
      : VOID_QUERY
  );

  if (isTopicLoading || isList3TopicsLoading || isAdditionalTopicsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Billboard />
      <PageIndent>
        <Topic content={content}>
          {(topic, enhancedTopic) => topicRenderer(topic, enhancedTopic, batId, list3Topics, additionalTopics)}
        </Topic>
      </PageIndent>
    </>
  );
}

TopicPage.propTypes = {
  history: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(TopicPage);
