import React from 'react';
import PropTypes from 'prop-types';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';
import cutDown from 'core/utils/cut-down';

import { CardTitle } from 'site/components/Texts';

import styles from './index.styl';

const requiredPayloadImports = ['search_result'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  search_result: {
    attributes: {
      title: '',
      body: '',
    },
  },
});


function SearchCard(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    announce,
  } = content.attributes;

  const {
    search_result: {
      title: searchTitle,
      body: searchBody,
    },
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link :global(b)
          color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  return (
    <Link
      to={link}
      type='card'
      className={scoped.wrapClassNames('link')}
    >
      <CardTitle className={styles.title}>
        <MarkdownWrapper inline>{searchTitle || headline}</MarkdownWrapper>
      </CardTitle>
      {(searchBody || announce) && <MarkdownWrapper inline>{searchBody || cutDown(announce)}</MarkdownWrapper>}
      <scoped.styles />
    </Link>
  );
}

SearchCard.propTypes = {
  theme: PropTypes.object,
  content: PropTypes.object,
};

const Card = cardHOC(SearchCard);

Card.requiredPayloadImports = requiredPayloadImports;
Card.displayName = 'SearchCard';

export default Card;
export { SearchCard as StorybookComponent };
