import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { withBreakpoint } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';


const MasonryFeed = (props) => {
  const {
    content,
    columns,
    isMobile,
    cards,
    cardsReplacement,
    cardsMobileAdditional,
    interitemSpacing,
  } = props;

  if (!content.length) {
    return null;
  }

  const replacements = cardsReplacement || {};
  const mobileAdditional = cardsMobileAdditional || {};
  const halfInteritemSpacing = interitemSpacing / 2;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .item
          padding ${halfInteritemSpacing}px
          break-inside avoid-column

          :global(.mobile) &
            padding ${halfInteritemSpacing}px 0

        .container
          margin ${-halfInteritemSpacing}px

          :global(.mobile) &
            margin ${-halfInteritemSpacing}px 0

        ._cardMargin
          margin-top ${isMobile ? 0 : -70}px
      `}</style>
    </scope>
  );

  return (
    <div className={scope.wrapClassNames('container')}>
      <Section>
        {content.map((topic, i) => {
          const cardProps = {
            position: i,
            content: topic,
          };

          const Replacement = replacements[i];
          const MobileAdditional = isMobile && mobileAdditional[i];
          const CardComponent = cards[i].component;

          const card = <CardComponent {...cardProps} />;

          const item = Replacement
            ? <Replacement card={card} />
            : card;


          const blockProps = {
            width: 12 / columns,
            mobile: 12,
            className: scope.wrapClassNames(
              'item',
              !isMobile && cards[i].margin && '_cardMargin'
            ),
          };

          return [
            MobileAdditional && (
              <Block
                {...blockProps}
                key={topic.id + 'additional'}
              >
                <MobileAdditional />
              </Block>
            ),
            <Block
              {...blockProps}
              key={topic.id}
            >
              {item}
            </Block>,
          ];
        })}
      </Section>
      <scope.styles />
    </div>
  );
};

MasonryFeed.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.func,
    margin: PropTypes.bool,
  })),
  /** @ignore */
  isMobile: PropTypes.bool,
  columns: PropTypes.number.isRequired,
  content: PropTypes.array.isRequired,
  interitemSpacing: PropTypes.number.isRequired,
  cardsReplacement: PropTypes.objectOf(PropTypes.func.isRequired),

  /**
   * Дополнительные компоненты, которые могут быть вставлены
   * между карточками на мобилке. На десктопе этот параметр игнорируется,
   * потому что вставка там поломает сетку.
   */
  cardsMobileAdditional: PropTypes.objectOf(PropTypes.func.isRequired),
};

MasonryFeed.defaultProps = {
  content: [],
  cards: [
    { component: Card1, margin: false },
    { component: Card2, margin: false },
    { component: Card1, margin: false },
    { component: Card2, margin: false },
    { component: Card2, margin: true },
    { component: Card1, margin: false },
    { component: Card2, margin: true },
    { component: Card1, margin: false },
  ],
  interitemSpacing: 20,
  columns: 4,
};

const MasonryFeedWithHOCs = withBreakpoint(MasonryFeed);
MasonryFeedWithHOCs.displayName = 'MasonryFeed';

export default MasonryFeedWithHOCs;
export { MasonryFeed as StorybookComponent };
