import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Scroller from 'core/components/Scroller';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import SocialShare from 'site/components/SocialShare';
import { Description } from 'site/components/Texts';
import { MaxWidthWrapper } from 'site/components/Wrappers';
import AdditionalTopics from 'site/components/AdditionalTopics';

import { VERTICAL_SMALL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

const relationships = resolveRelationships(...[
  ['tags', 'authors'],
  {},
  { tags: [], authors: [] },
]);

function TopicFooter(props) {
  const {
    content,
    isMobile,
    theme,
    additionalTopics,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_SMALL_INDENT;

  const {
    tags,
    authors,
  } = relationships(content);

  const Wrapper = isMobile ? Scroller : MaxWidthWrapper;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .authors
        .share
        .${styles.tagList}
          margin-top ${vertical}px

        .${styles.tagList}
          border-bottom 1px solid ${theme.colors.grey1}

        .${styles.tagItem}
          text-decoration none
          color ${theme.colors.grey2}
          &:hover
            color ${theme.colors.active1}
          &:visited
          &:active
            color ${theme.colors.active3}
      `}</style>
    </scope>
  );

  return (
    <>
      {authors.length > 0 && (
        <div className={scoped.wrapClassNames(styles.authors)}>
          <Wrapper>
            <Description>
              {authors.length === 1 ? 'Автор: ' : 'Авторы: '}
              {authors.map((author, i) => {
                const {
                  id: authorId,
                  attributes: {
                    name,
                    slug,
                  },
                } = author;

                return (
                  <Fragment key={authorId}>
                    <Link to={'/authors/' + slug} type='secondary'>
                      {name}
                    </Link>
                    {authors[i + 1] && ', '}
                  </Fragment>
                );
              })}
            </Description>
          </Wrapper>
        </div>
      )}

      {tags.length > 0 && (
        <div className={scoped.wrapClassNames(styles.tagList)}>
          <Wrapper>
            <Description>
              {tags.map(tag => {
                return (
                  <Link
                    className={scoped.wrapClassNames(styles.tagItem)}
                    key={tag.id}
                    to={`/label/${tag.attributes.slug}`}
                  >
                    {tag.attributes.title}
                  </Link>
                );
              })}
            </Description>
          </Wrapper>
        </div>
      )}

      <MaxWidthWrapper className={scoped.wrapClassNames('share')}>
        <SocialShare />
      </MaxWidthWrapper>

      {additionalTopics?.length > 0 && <AdditionalTopics content={additionalTopics} />}
      <scoped.styles />
    </>
  );
}

TopicFooter.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  additionalTopics: PropTypes.array,
};

export default withTheme(withBreakpoint(TopicFooter));
