import { INDENT, CONTENT_MAX_WIDTH } from 'site/constants';

import styles from './index.styl';

export default function SetWidthLimitToContentWidth({ children }) {
  return (
    <div className={styles.setWidthLimitToContentWidth}>
      <style jsx>{`
        .${styles.setWidthLimitToContentWidth}
          max-width ${CONTENT_MAX_WIDTH}px

          @media screen and (max-width: ${CONTENT_MAX_WIDTH + 2 * INDENT}px)
            padding 0 ${INDENT}px
            max-width none
      `}</style>
      {children}
    </div>
  );
}
